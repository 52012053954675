export const tooltipContent = {
  lineGraphCard: {
    "en-US":
      "Circles represent your selected items. Diamonds represent comparison items. Stars represent any baselines or goals that are set.",
  },
  staffGraphsToggle: {
    "en-US":
      "You can view staff data in two different ways: as a standard data table or as bubbles highlighting areas of opportunity.",
  },
  roundingGraphsToggle: {
    "en-US":
      "You can view rounding data in three different ways: as a line graph, a standard data table, or as bubbles highlighting areas of opportunity.",
  },
  missingDataGraph: {
    "en-US": "No data is available for your graph card.",
  },
  performanceCard: {
    "en-US":
      "Total % is performant events (the person sanitized or washed) divided by total opportunities. The +% or -% change is the change in performance from beginning to end within the date range selected.",
  },
  performance: {
    "en-US": "The performant percentage over the selected time period.",
  },
  performantOpportunities: {
    "en-US":
      "When a hand hygiene event could have been performed, AND was performed, ex. walking into a patient room AND hand sanitizing.",
  },
  roundingPerformanceCard: {
    "en-US":
      "Total % is performant events (a round was successfully performed on a room) divided by total opportunities.",
  },
  totalOpportunities: {
    "en-US":
      "When a hand hygiene event could have been performed, ex. walking into a patient room.",
  },
  executiveDashboard: {
    "en-US":
      "This heatmap shows Hand Hygiene performance over the last fiscal year in %",
  },
  bestDate: {
    "en-US":
      "The best performing day, week, or month - depending on which aggregate is selected.",
  },
  excelDownload: {
    "en-US":
      "Only one Customer can be selected in the filters to share this Report, or there is no report available for this selection.",
  },
  rtibs: {
    "en-US":
      "Rtibs show hand hygiene compliance within a floor plan. The maps shown are per-unit.",
  },
  otibs: {
    "en-US":
      "Otibs show hand hygiene compliance within a floor plan over a selected period of time. The maps shown are per-unit.",
  },
  roundingMetrics: {
    "en-US":
      "Average time between rounds is how long patients are going without being visited by a nurse. Nurse room time % is the percentage of time patients have at least one nurse in their room. Average round duration is how long nurses spend in patient rooms each time they round.",
  },
  cases: {
    "en-US":
      "Staff cases show the open and archived cases created. Patient cases show the opened and archived cases created by patient room. ",
  },
  overviewPage: {
    "en-US":
      "Below are all of the SwipeSense modules. The modules you have access to are currently enabled. If you would like to enable any other modules, please contact your SwipeSense representative via our contact us buttons provided below.",
  },
  otherMetricsHH: {
    "en-US":
      "Overview of key performance categories contributing to your total performance within the time you have selected.",
  },
  missingDataMetrics: {
    "en-US": "No data is available for your metrics card.",
  },
  histogram: {
    "en-US":
      "This Histogram breaks down how many staff members (in %) fall into each compliance percentage bracket over the selected time period.",
  },
  barChartExecutive: {
    "en-US":
      "Stacked bar chart illustrating performant and non-performant opportunities, and their performance by facility for the current month.",
  },
  groupUnitTypePerformance: {
    "en-US": "Grid illustrating compliance % for each Group or Unit.",
  },
  sparklineCardsExecutive: {
    "en-US":
      "Click on the card below to be taken to a more detailed breakdown of your facility.",
  },
  roomsOverview: {
    visitLength: {
      "en-US":
        "The average duration of each visit to the patient room(s) based on the filters applied.",
    },
    timeBetween: {
      "en-US":
        "The average amount of time between all visits based on the filters applied.",
    },
    timeAlone: {
      "en-US":
        "The percentage of time patients were alone in their room without a badged nurse present.",
    },
  },
  staffOverview: {
    averageVisitLength: {
      "en-US":
        "The average duration of each visit to the patient room(s) based on the filters applied.",
    },
    visitCount: {
      "en-US":
        "Total number of individual visits completed by staff based on the filters applied.",
    },
  },
};
